import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContext';
import { useEffect, useRef, useState } from 'react';
import { useOnClickOutside, useUpdateEffect, useWindowSize } from 'usehooks-ts'
import { ReactComponent as ArrowDown } from '../../assets/images/arrow_down.svg';
import logo from '../../assets/images/nfttrendsLogo.svg';
import LanguageSelector from '../Selectors/LanguagesSelector'; 
import { Roles, shortFormatRole } from '../../common/constants';
import { type Role } from '../../common/types';

const NavbarAdmin = ({handleLogo}: {handleLogo?:boolean}) => {
  const { logoutHandler, userData } = useAuthContext(); 
  const [active, setActive] = useState('people'); 
  const [moreOpen, setMoreOpen] = useState(false); 
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation(); 
  const ref = useRef<any>(null);
  const { width } = useWindowSize();

  const handleClickOutside = () => {
    if(moreOpen) {
      setMoreOpen(false); 
      handleActiveOption(); 
    }
  }

  const toggleMore = () => {
    setMoreOpen(!moreOpen);
    if(moreOpen) {
      handleActiveOption();  
    } else {
      setActive('more'); 
    }
  };

  const handleActiveOption = () => {
     if(location.pathname.includes('profile')) {
      setActive('profile'); 
    } else if(location.pathname.includes('people')) {
      setActive('people'); 
    } else if(location.pathname.includes('inventory')) {
        setActive('inventory'); 
    } else if(location.pathname.includes('subscription')) {
      setActive('subscription'); 
    } else if(location.pathname.includes('coming_soon')) {
      setActive('more');
    }
  }

  const handleNavigateToComingSoon = () => {
    navigate('/coming_soon');
  }

  const handlerClick = (type: string) => {
    setActive(type); 
    if(moreOpen) {
      setMoreOpen(false); 
    }
  } 

   useEffect(() => {
    handleActiveOption(); 
  }, []);  

  
  useUpdateEffect(() => {
    handleActiveOption(); 
  }, [handleLogo, width, location.pathname]);

  useOnClickOutside(ref, handleClickOutside);
  
  return (
    <div className='relative bg-white w-full border-b border-b-[#CBCBCB]'>
      <div className='2xl:container 2xl:mx-auto flex items-center justify-between px-[20px] md:px-[40px] 2xl:px-[40px] h-[56px] lg:h-[86px] lg:py-none'>
      <div className='lg:hidden w-full h-[1px] bg-gray-lg absolute top-0 left-0'></div>
      <div className='flex h-full md:space-x-4 px-2 lg:px-0 items-center text-[12px] xs:text-[14px] w-full lg:w-auto justify-between md:justify-center ease-out' ref={ref}>
        <div className='hidden lg:block text-[32px] font-medium pr-[20px]'>
             <NavLink onClick={(e) => { handlerClick('people'); }} to='/admin/people'>
               <img src={logo} alt='logo'/>
             </NavLink>
          </div>
        <div className='flex space-x-2'>
            <NavLink to='/admin/profile' onClick={(e) => { handlerClick('profile'); }} className={`${active === 'profile' ? 'font-semibold text-blue-normal border-b border-b-2 border-blue-normal py-1' : 'font-normal'} flex lg:uppercase`}>
{/*               <div className={`rounded-full ${active === 'profile' ? 'bg-black' : 'bg-gray-sm  hidden lg:block'} h-4 w-4 xs:h-5 xs:w-5 mr-2`}></div> */}
              {t('navbar.profile')}</NavLink>
        </div>
        <div className='flex space-x-2'>
            <NavLink to='/admin/people' onClick={(e) => { handlerClick('people'); }} className={`${active === 'people' ? 'font-semibold text-blue-normal border-b border-b-2 border-blue-normal py-1' : 'font-normal'} flex lg:uppercase`}>
     {/*          <div className={`rounded-full ${active === 'people' ? 'bg-black' : 'bg-gray-sm  hidden lg:block'} h-4 w-4 xs:h-5 xs:w-5 mr-2`}></div> */}
              {t('navbar.people')}</NavLink>
        </div>
        <div className='flex space-x-2'>
            <NavLink to='/admin/inventory' onClick={(e) => { handlerClick('inventory'); }} className={`${active === 'inventory' ? 'font-semibold text-blue-normal border-b border-b-2 border-blue-normal py-1' : 'font-normal'} flex lg:uppercase`}>
           {/*    <div className={`rounded-full ${active === 'inventory' ? 'bg-black' : 'bg-gray-sm  hidden lg:block'} h-4 w-4 xs:h-5 xs:w-5 mr-2`}></div> */}
              {t('navbar.inventory')}</NavLink>
        </div>
        <div className='relative flex space-x-2 h-full items-center' onClick={toggleMore} >
           {/*  <div className={`rounded-full cursor-pointer ${active === 'more' ? 'bg-black' : 'bg-gray-sm  hidden lg:block'} h-4 w-4 xs:h-5 xs:w-5`}></div> */}
            <div className={`${active === 'more' ? 'font-semibold text-blue-normal border-b border-b-2 border-blue-normal py-1' : ''} hidden lg:block cursor-pointer lg:uppercase`}>{t('navbar.more')}</div>
            <NavLink to='/admin/more' onClick={(e) => { handlerClick('more'); }} className={`${active === 'more'  ? 'font-bold lg:hidden border-b border-b-2 py-1 text-blue-normal' : 'font-normal lg:hidden'}`}>{t('navbar.more')}</NavLink>
            <div className='pointer-events-none inset-y-0 flex items-center hidden lg:block'>
              {!moreOpen ? <ArrowDown className='h-2.5 w-2.5' /> :  <ArrowDown className='h-2.5 w-2.5 rotate-180 text-blue-normal' />}
            </div>
            {moreOpen && (
            <div className="absolute hidden lg:block top-[70px] right-[-80px]  bg-white rounded-md shadow-2xl z-10 w-[200px] ease-out">
              {/* <div className='h-[2px] w-[80px] bg-blue-normal mt-[-2px] ml-1'></div> */}
              <ul className="pt-[32px] pb-[25px] pl-[30px] space-y-3 text-[16px] font-medium">
                <li className="hover:text-gray-dark cursor-pointer" onClick={handleNavigateToComingSoon}>{t('navbar.option_1')}</li>
                <li className="hover:text-gray-dark cursor-pointer" onClick={handleNavigateToComingSoon}>{t('navbar.option_2')}</li>
                <li className="hover:text-gray-dark cursor-pointer" onClick={handleNavigateToComingSoon}>{t('navbar.option_3')}</li>
              </ul>
            </div>
          )}
        </div>
        
      </div>

      <div className='hidden lg:flex items-center '>
          <div className='mr-[35px]'>
             <LanguageSelector/>
          </div>

          <div className='h-[40px] w-[40px] text-white flex items-center justify-center rounded-full font-semibold mr-2'>
            <img src={userData?.picture} className='w-full h-full rounded-full' alt='photo'/>
          </div>
          
          <button
          className="py-2 rounded-lg cursor-pointer text-[16px] text-blue-normal cursor-pointer font-medium"
          onClick={() => {
            logoutHandler();
          }}
        >
          Log out
        </button>
        <span className={'pl-1 font-bold text-blue-normal'}>{`(${shortFormatRole[userData?.userRole ?? '']})`}</span>
      </div>
    
      </div>
    </div>
  );
};

export default NavbarAdmin;
