import { Oval } from 'react-loader-spinner';
import React from 'react'; 

function LoaderBase() {
  return (
    <Oval
        height={80}
        width={80}
        color="#2440D6"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel='oval-loading'
        secondaryColor="#CBCBCB"
        strokeWidth={2}
        strokeWidthSecondary={2}
        />
  )
}

export default LoaderBase;